export const EDITOR_UI_LANGUAGES = {
    'ru-RU': 'Русский',
    'en-US': 'English',
    'es-PA': 'Español',
    'fr-FR': 'Français',
    'tt-RU': 'Татарча',
    'ba-RU': 'Башҡорт',
    'pt-BR': 'Português',
    'de-DE': 'Deutsch',
    'it-IT': 'Italiano',
    'be-BY': 'Беларуская',
    'kk-KZ': 'Қазақша (Kazakh)',
    'ky-KG': 'Кыргызча (Kyrgyz)',
    'hy-AM': 'Հայերեն (Armenian)',
    'zh-CN': '中文 (Chinese)',
    'hi-IN': 'हिन्दी (Hindi)',
    'ja-JP': '日本語 (Japanese)',
};

export const AMR_SOURCE = 'amrApp/';
