import React from 'react';

export function MobilePage() {
    return (
        <>
            <div className="main-block main-block-mobile">
                <a
                    href="https://myoffice.ru/?utm_source=edit.myoffice.ru&utm_medium=referral&utm_campaign=amr"
                    target="_blank"
                    rel="noreferrer"
                    className="main-block-logo"
                >
                    <img src="img/myoffice-logo.png" />
                </a>
                <div className="main-block-title">
                    МойОфис&nbsp;Веб&#8209;редакторы
                    <br />
                    Пробная версия
                </div>
                <div className="main-block-text-mobile">
                    <p>
                        Для работы с редакторами зайдите с{' '}
                        <strong>компьютера</strong>.
                    </p>
                    <p>
                        Для <strong>мобильных</strong> устройств скачайте
                        бесплатное приложение «МойОфис&nbsp;Документы».
                    </p>
                    <p>
                        <a href="https://itunes.apple.com/ru/app/id994489415?ls=1&mt=8">
                            <img src="https://myoffice.ru/wp-content/themes/template/mobile/img/appstore.png" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.ncloudtech.cloudoffice&hl=ru">
                            <img src="https://myoffice.ru/wp-content/themes/template/mobile/img/gp.png" />
                        </a>
                        <a href="https://appgallery.huawei.com/#/app/C102100745?locale=ru_RU">
                            <img src="https://myoffice.ru/wp-content/themes/template/mobile/img/appgallery.png" />
                        </a>
                    </p>
                </div>
            </div>
            <div className="main-block-footer main-block-footer-mobile">
                <div className="main-block-footer-inner main-block-footer-inner-mobile">
                    <a
                        href="https://myoffice.ru/policy/?utm_source=edit.myoffice.ru&utm_medium=referral&utm_campaign=amr"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Политика&nbsp;конфиденциальности
                    </a>
                    <a
                        href="https://myoffice.ru/tos/?utm_source=edit.myoffice.ru&utm_medium=referral&utm_campaign=amr"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Условия&nbsp;использования
                    </a>
                    <div>
                        © ООО «Новые Облачные Технологии», 2013–
                        {new Date().getFullYear()}.
                        Все&nbsp;права&nbsp;защищены.
                    </div>
                </div>
            </div>
        </>
    );
}
